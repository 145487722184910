<template>
  <b-card>
    <b-overlay rounded="sm" opacity="0.8">
      <b-card class="card-company-table">
        <template>
          <b-row class="match-height">
            <b-col lg="11">
              <h3>{{ getSelectedImage ? "Moderating: " + getSelectedImage.name : "Moderating" }}</h3>
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col lg="11">
              <span> <b>Listing Name: </b>{{ getSelectedImage ? getSelectedImage.listingName : "N/A" }} </span>
              <span>
                <b>Facility: </b>{{ getSelectedImage ? getSelectedImage.facilityName : "N/A" }}
                <small class="ts-3">({{ getSelectedImage ? getSelectedImage.facilityLicense : "N/A" }}) </small>
              </span>

              <span> <b>Uploaded by: </b>{{ getSelectedImage.uploadUser }} </span>
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col lg="8">
              <br />
              <template v-if="!isImageDetected && !isPDFDetected && !isVideoDetected">
                <div class="d-flex justify-content-center mb-1">
                  <b-spinner label="Loading..." />
                </div>
              </template>

              <template v-else-if="isImageDetected">
                <b-img center :src="imgBytes" fluid alt="Unmoderated" />
              </template>

              <template v-else-if="isPDFDetected">
                <b-embed type="iframe" :src="pdfUrl" allowfullscreen />
              </template>

              <template v-else-if="isVideoDetected">
                <div>
                  <b-embed type="video" controls>
                    <source :src="videoBytes" type="video/mp4" />
                  </b-embed>
                </div>
              </template>

              <template v-if="!getStoreLoading">
                <div>
                  <br />
                  <b-form-group label="Reason" label-for="reject-reason-select" label-cols-md="2">
                    <b-form-select id="reject-reason-select" v-model="rejectReason" :options="rejectReasons" />
                  </b-form-group>
                  <b-form-group label="Note" label-for="reject-note" label-cols-md="2">
                    <b-form-textarea id="reject-note" placeholder="Rejection Note to user" rows="3" v-model="rejectNote" />
                    <b-button variant="danger" @click="onRejectMedia" :disabled="!validateFileType">
                      <b-spinner v-if="isRejectionPending" small style="margin-bottom: 3px"></b-spinner>
                      Reject
                    </b-button>
                  </b-form-group>
                  <br />
                  <span>
                    <b-button v-if="validateFileType" class="mr-1" variant="primary" @click="onSaveFile"> Download File </b-button>
                    <b-button class="mr-1" variant="success" :disabled="!validateFileType || getStoreLoading" @click="onApproveMedia">
                      <b-spinner v-if="isApprovalPending" small style="margin-bottom: 3px"></b-spinner>
                      Approve
                    </b-button>
                    <b-button class="mr-1" variant="outline-secondary" @click="onClose()"> Close </b-button>
                  </span>
                </div>
              </template>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  getSelectedImage,
  getSelectedImageDetails,
  onLoadImageDetails,
  setSelectedImageDetails,
  getStoreLoading,
  onMediaModeration,
  onSaveDownloadedFile
} from "@/views/moderate-images/useModerateImages";
import { onMounted, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import pdf from "vue-pdf";
import { useRouter } from "@core/utils/utils";

export default {
  name: "ModerateImagesApprove",
  components: {
    Ripple,
    pdf
  },
  directives: {
    Ripple
  },
  emits: ["update:visible", "update-table"],
  setup({ emit }) {
    const { router } = useRouter();
    const imgBytes = ref("");
    const videoBytes = ref("");
    const isApprovalPending = ref(false);
    const isRejectionPending = ref(false);
    const isImageDetected = ref(false);
    const isPDFDetected = ref(false);
    const isVideoDetected = ref(false);
    const validImgTypes = ["jpg", "jpeg", "png", "svg", "apng", "avif", "gif", "jfif", "pjpeg", "pjp", "webp", "bmp", "tif", "tiff", "ico", "cur"];
    const fileExtension = ref("");
    const rejectNote = ref(null);
    const pdfUrl = ref("");
    const rejectReason = ref("pick");
    const rejectReasons = [
      { value: "pick", text: "Please select a rejection reason" },
      { value: "size", text: "Media size is invalid" },
      { value: "resolution", text: "Media resolution is poor" },
      { value: "nsfw", text: "Media is in poor taste" }
    ];

    const init = async () => {
      console.log("0", getSelectedImage.value);
      fileExtension.value = getSelectedImage.value.name.split(".").pop().toLowerCase();
      await onLoadImageDetails();
      if (validImgTypes.includes(fileExtension.value)) {
        isImageDetected.value = true;
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            imgBytes.value = reader.result;
          },
          false
        );
        reader.readAsDataURL(getSelectedImageDetails.value);
      } else if (fileExtension.value === "mp4") {
        console.log("1", getSelectedImageDetails.value);
        isVideoDetected.value = true;
        console.log("entering video section");
        let blob = new Blob([getSelectedImageDetails.value]);
        console.log("2", blob);
        videoBytes.value = window.URL.createObjectURL(blob);
      }
    };

    onMounted(async () => {
      await init();
    });

    const validateFileType = () => {
      return !!isVideoDetected.value || !!isImageDetected.value || !!isPDFDetected.value;
    };

    const onApproveMedia = async () => {
      isApprovalPending.value = true;
      await onMediaModeration(true);
      isApprovalPending.value = false;
      onClose();
    };

    const onRejectMedia = async () => {
      isRejectionPending.value = true;
      await onMediaModeration(false, rejectReason, rejectNote);
      isRejectionPending.value = false;
      onClose();
    };

    const onSaveFile = async () => {
      console.log(getSelectedImage.value.name);
      await onSaveDownloadedFile(getSelectedImageDetails.value, getSelectedImage.value.name, fileExtension.value);
    };

    const onClose = () => {
      setSelectedImageDetails(null);
      router.back();
    };

    return {
      getSelectedImage,
      getSelectedImageDetails,
      imgBytes,
      isApprovalPending,
      isRejectionPending,
      isImageDetected,
      isPDFDetected,
      isVideoDetected,
      validImgTypes,
      videoBytes,
      pdfUrl,
      validateFileType,
      init,
      onApproveMedia,
      onRejectMedia,
      onSaveFile,
      setSelectedImageDetails,
      getStoreLoading,
      rejectReason,
      rejectReasons,
      rejectNote,
      onClose
    };
  }
};
</script>
<style scoped></style>
